import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import * as RadioGroup from "@radix-ui/react-radio-group";
import * as Select from "@radix-ui/react-select";
import materials from "app/data/materials";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon";
import QuoteImage from "app/assets/quote.svg";
import countries from "app/data/countries.json";
import countryPhoneCodes from "app/data/country-phone-codes.json";

import { filterSettings } from "app/pages/main";

import { Header } from "app/components/header/header";
import {
  ChartIcon,
  GlobeIcon,
  GuageIcon,
  PackageIcon,
  SafetyIcon,
} from "app/components/custom-icons/custom-icons";
import { Divider } from "app/components/divider/divider";

const TextInput = ({
  handleChange,
  value,
  name,
  label,
  placeholder,
  required,
  autocomplete,
  type,
}) => (
  <div className="flex flex-col gap-1 grow">
    <div className="flex justify-between items-center">
      <label className="text-sm text-stone-800 flex gap-1" htmlFor={name}>
        {label}
        {required && <div className="text-xs text-[#C12960]">*</div>}
      </label>
      {!required && <div className="text-xs text-[#C6CCE8]">Optional</div>}
    </div>
    <input
      type={type || `text`}
      id={name}
      required={required}
      onChange={(event) => handleChange(name, event.target.value)}
      value={value}
      className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
      tabIndex="0"
      placeholder={placeholder}
      autoComplete={autocomplete}
    />
  </div>
);

const SelectInput = ({ handleChange, name, value, label, items, required }) => (
  <div className="flex flex-col gap-1">
    <div className="flex justify-between gap-4 items-center">
      <label className="text-sm text-stone-800" htmlFor={name}>
        {label}
      </label>
      {!required && <div className="text-xs text-[#C6CCE8]">Optional</div>}
    </div>
    <Select.Root
      onValueChange={(value) => handleChange(name, value)}
      value={value}
      required={required}
    >
      <Select.Trigger
        aria-label={label}
        className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300 flex justify-between items-center"
      >
        <Select.Value placeholder="Please select" />
        <Select.Icon className="flex">
          <i className="material-icons-round text-xl text-stone-600 leading-none">
            expand_more
          </i>
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content>
          <Select.ScrollUpButton>
            <i className="material-icons-round text-xl text-stone-600">
              expand_less
            </i>
          </Select.ScrollUpButton>
          <Select.Viewport className="bg-white rounded-lg shadow-3xl border border-blue-50">
            {items.map(({ label, value }) => (
              <Select.Item
                key={value}
                value={value}
                className="p-2 text-sm flex items-center gap-2 cursor-pointer"
              >
                <Select.ItemText>{label}</Select.ItemText>
                <Select.ItemIndicator className="flex">
                  <i className="material-icons-round text-sm text-stone-600">
                    done
                  </i>
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton>
            <i className="material-icons-round text-xl text-stone-600">
              expand_more
            </i>
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  </div>
);

export const Material = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const defaultFields = {
    firstName: ``,
    lastName: ``,
    businessName: ``,
    position: ``,
    deliveryCountry: ``,
    email: ``,
    countryName: ``,
    telephone: ``,
    quantity: ``,
    shipmentFrequency: `recurring`,
    additionalDetails: ``,
  };
  const [fields, setFields] = useState(defaultFields);
  const { materialId } = useParams();
  const material = materials.find(
    ({ friendly_name }) => friendly_name === materialId,
  );
  const handleAdd = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      const response = await fetch(`/api/send-email`, {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`,
        },
        body: JSON.stringify({
          body: `<b>material</b>: ${materialId}<br />${Object.entries(fields)
            .map(([key, value]) => `<b>${key}</b>: ${value}`)
            .join("<br />")}`,
        }),
      });

      if (!response.ok) {
        throw new Error();
      }

      setIsSubmitted(true);
      setIsLoading(false);
      setFields(defaultFields);
    } catch (error) {
      console.error(error);
      alert(`An error occurred, please try again`);
      setIsLoading(false);
    }
  };
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };

  useEffect(() => {
    if (!modalOpen) {
      setIsSubmitted(false);
    }
  }, [modalOpen]);

  return (
    <>
      <Header />
      <div className={`flex flex-col items-center pb-[80px]`}>
        <div className="px-4 sm:px-0 w-full sm:w-[990px]">
          <span className="text-ellipsis whitespace-nowrap overflow-hidden block pt-[40px]">
            <Link to={"/"}>Materials</Link>
            <span className="hidden md:inline text-[#595F77]">
              {" "}
              <Icon
                glyphName="chevron_right"
                className="relative top-[4px] !inline text-[#595F77]"
              />
              {material.friendly_name}
            </span>
          </span>
          <h2 className="text-[60px] mb-[40px]">{material.friendly_name}</h2>
        </div>
        <div className={`flex flex-col sm:flex-row px-4 sm:px-0`}>
          <div className={`sm:max-w-[640px]`}>
            <div
              style={{
                backgroundImage: `url("${material.image}")`,
                backgroundSize: `contain`,
                backgroundRepeat: `no-repeat`,
                backgroundPosition: `center`,
              }}
              className="h-[290px] mb-[24px]"
            ></div>
            <h3 className="text-[26px] mb-[24px]">Description</h3>
            <p>{material.description}</p>
            <Divider />

            {Object.entries(filterSettings).map(
              ([key, { list, tenScale, label }]) => (
                <div key={key}>
                  {list ? (
                    <>
                      <h3 className="text-[26px] mb-[24px]">{label}</h3>
                      <ul>
                        {material[key].split(`,`).map((item) => (
                          <li
                            key={`${key}_${item}`}
                            className="inline-block mr-3"
                          >
                            <strong>{item}</strong>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      {tenScale ? (
                        <div>
                          <div className="flex">
                            <p className="pb-[8px] text-[#282F44]">{label}</p>
                            <div className="ml-auto text-[#464C64]">
                              {material[key]}
                            </div>
                          </div>
                          <div
                            className={`flex bg-white rounded-sm gap-1 overflow-hidden`}
                          >
                            {new Array(material[key] ?? 0)
                              .fill(undefined)
                              .map((_, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={`h-[4px] w-[calc(10%-2px)] bg-[#173991]`}
                                  ></div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        <>
                          <h3 className="text-[26px] mb-[24px]">{label}</h3>
                          <ul>
                            <li>
                              <strong>{material[key]}</strong>
                            </li>
                          </ul>
                        </>
                      )}
                    </>
                  )}
                  <Divider />
                </div>
              ),
            )}

            {(material.safety_sheet || material.technical_sheet) && (
              <>
                <Divider />
                <h3 className="text-[26px] mb-[24px]">Data Sheets</h3>
                <div className="flex flex-row gap-4">
                  {material.safety_sheet && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={material.safety_sheet}
                      className="grow"
                    >
                      <div className="flex bg-white shadow p-[16px] rounded-lg space-x-[16px]">
                        <SafetyIcon />
                        <p>Safety Sheet</p>
                      </div>
                    </a>
                  )}
                  {material.technical_sheet && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={material.technical_sheet}
                      className="grow"
                    >
                      <div className="flex bg-white shadow p-[16px] rounded-lg space-x-[16px]">
                        <ChartIcon />
                        <p>Technical Sheet</p>
                      </div>
                    </a>
                  )}
                </div>
              </>
            )}
          </div>

          <div className={`sm:w-[350px]`}>
            <div
              className={`bg-white shadow-lg rounded-lg mt-8 sm:mt-0 sm:ml-[56px] p-[24px] sticky top-[24px]`}
            >
              <h2 className={`text-[26px]`}>Request Quote</h2>
              <p className={`text-[13px] text-[#464C64]`}>
                Each request is sent directly to our materials experts for
                review.
              </p>
              <p className={`font-medium my-[24px]`}>Each quote includes</p>

              <ul className={`space-y-[8px]`}>
                <li className="flex items-center space-x-[10px]">
                  <PackageIcon />
                  <p>Minimum order quantities</p>
                </li>
                <li className="flex items-center space-x-[10px]">
                  <GuageIcon />
                  <p>Manufacturing rates</p>
                </li>
                <li className="flex items-center space-x-[10px]">
                  <GlobeIcon />
                  <p>Regional availability</p>
                </li>
              </ul>

              <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                <Dialog.Trigger asChild>
                  <Button
                    secondary
                    label="Request a Quote"
                    className={`w-full justify-center mt-[24px]`}
                  />
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay className="fixed bg-black/20 inset-0" />
                  <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] sm:min-w-[34rem] max-h-screen max-w-lg overflow-scroll">
                    {isSubmitted ? (
                      <div className="px-8 py-20 flex flex-col items-center justify-center">
                        <img
                          src={QuoteImage}
                          alt="Logo"
                          className="w-full mb-8 max-w-[12.5rem]"
                        />
                        <h2 className="text-stone-800 text-3xl font-medium text-center">
                          Quote Request Received
                        </h2>
                        <div className="text-stone-500 text-base max-w-[29rem] text-center">
                          Thanks for requesting a quote. One of our material
                          sourcing experts will be in touch.
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="flex p-4 items-start">
                          <div className="flex grow flex-col">
                            <Dialog.Title className="text-lg">
                              Request a Quote
                            </Dialog.Title>
                            <div className="text-stone-700 text-sm">
                              Submit a request below to obtain a quote.
                            </div>
                          </div>
                          <Dialog.Close tabIndex="-1">
                            <i className="material-icons-round text-xl">
                              close
                            </i>
                          </Dialog.Close>
                        </div>
                        <form onSubmit={handleAdd}>
                          <div className="flex flex-col p-4 gap-4">
                            <div className="flex gap-8">
                              <TextInput
                                handleChange={handleChange}
                                value={fields.firstName}
                                name="firstName"
                                label="First Name"
                                required
                                autocomplete="given-name"
                              />
                              <TextInput
                                handleChange={handleChange}
                                value={fields.lastName}
                                name="lastName"
                                label="Last Name"
                                required
                                autocomplete="family-name"
                              />
                            </div>
                            <div className="flex gap-8">
                              <TextInput
                                handleChange={handleChange}
                                value={fields.businessName}
                                name="businessName"
                                label="Business Name"
                                required
                              />
                              <TextInput
                                handleChange={handleChange}
                                value={fields.position}
                                name="position"
                                label="Position"
                              />
                            </div>
                            <SelectInput
                              handleChange={handleChange}
                              value={fields.deliveryCountry}
                              name="deliveryCountry"
                              label="Delivery Country"
                              items={countries.map(({ name }) => ({
                                label: name,
                                value: name,
                              }))}
                            />
                            <TextInput
                              handleChange={handleChange}
                              value={fields.email}
                              name="email"
                              label="Email Address"
                              type="email"
                              autocomplete="email"
                              required
                            />
                            <div className="flex gap-8">
                              <SelectInput
                                handleChange={handleChange}
                                value={fields.countryCode}
                                name="countryCode"
                                label="Country name or prefix code"
                                items={countryPhoneCodes.map(
                                  ({ name, dial_code }) => ({
                                    label: `${name} (${dial_code})`,
                                    value: `${name} (${dial_code})`,
                                  }),
                                )}
                              />
                              <TextInput
                                handleChange={handleChange}
                                value={fields.telephone}
                                name="telephone"
                                label="Telephone Number"
                                type="number"
                              />
                            </div>
                            <TextInput
                              handleChange={handleChange}
                              value={fields.quantity}
                              name="quantity"
                              label="Quantity Required"
                              required
                              placeholder="100kgs"
                            />
                            <div className="flex flex-col gap-1">
                              <label
                                className="text-sm text-stone-800 flex gap-1"
                                htmlFor="recurring"
                              >
                                Shipment Frequency
                                <div className="text-xs text-[#C12960]">*</div>
                              </label>
                              <RadioGroup.Root
                                defaultValue="default"
                                aria-label="Shipment Frequency"
                                className="rounded-md border border-solid border-stone-300"
                                onValueChange={(value) =>
                                  handleChange(`shipmentFrequency`, value)
                                }
                                value={fields.shipmentFrequency}
                                required
                              >
                                <div className="border-b border-solid border-stone-300 p-3 text-sm flex gap-3 items-center">
                                  <RadioGroup.Item
                                    value="recurring"
                                    id="recurring"
                                    className="flex w-6 h-6 rounded-full border border-solid border-stone-300 after:content-[''] after:block after:rounded-full after:bg-stone-100 after:w-3 after:h-3 after:m-auto aria-checked:after:bg-blue-700"
                                  >
                                    <RadioGroup.Indicator />
                                  </RadioGroup.Item>
                                  <label
                                    htmlFor="recurring"
                                    className="cursor-pointer"
                                  >
                                    Recurring
                                  </label>
                                </div>
                                <div className="p-3 text-sm flex gap-3 items-center">
                                  <RadioGroup.Item
                                    value="one-off"
                                    id="one-off"
                                    className="flex w-6 h-6 rounded-full border border-solid border-stone-300 after:content-[''] after:block after:rounded-full after:bg-stone-100 after:w-3 after:h-3 after:m-auto aria-checked:after:bg-blue-700"
                                  >
                                    <RadioGroup.Indicator />
                                  </RadioGroup.Item>
                                  <label
                                    htmlFor="one-off"
                                    className="cursor-pointer"
                                  >
                                    One off
                                  </label>
                                </div>
                              </RadioGroup.Root>
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="flex justify-between items-center">
                                <label
                                  className="text-sm text-stone-800"
                                  htmlFor="additional-details"
                                >
                                  Additional Details
                                </label>
                                <div className="text-xs text-[#C6CCE8]">
                                  Optional
                                </div>
                              </div>
                              <textarea
                                id="additional-details"
                                onChange={(event) =>
                                  handleChange(
                                    `additionalDetails`,
                                    event.target.value,
                                  )
                                }
                                value={fields.additionalDetails}
                                className="rounded-md border border-solid border-stone-300 px-2.5 py-2 min-h-[5rem] text-sm text-stone-800 placeholder:text-stone-300"
                              />
                            </div>
                          </div>
                          <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                            <Button
                              type="submit"
                              secondary
                              label="Submit"
                              className={`justify-center mt-[24px]`}
                              disabled={isLoading}
                            />
                          </div>
                        </form>
                      </>
                    )}
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
