import materials from "./materials.json";

export default await Promise.all(
  materials.map(
    async ({
      image_name,
      safety_sheet_name,
      technical_sheet_name,
      ...rest
    }) => ({
      ...rest,
      image: (await import(`../assets/images/${image_name}/block.png`)).default,
      safety_sheet:
        safety_sheet_name &&
        (await import(`../assets/safety-sheets/${safety_sheet_name}`)).default,
      technical_sheet:
        technical_sheet_name &&
        (await import(`../assets/technical-sheets/${technical_sheet_name}`))
          .default,
    }),
  ),
);
