import { BrowserRouter } from "react-router-dom";
import { MainNav } from "./main-nav";

function App() {
  return (
    <BrowserRouter>
      <MainNav appName="Projects" />
      <MainNav appName="Projects" user={{}} env="dev" />
    </BrowserRouter>
  );
}

export default App;
