import packageJson from "../../package.json";

import { Routes, Route, Outlet } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Main } from "app/pages/main";
import { Material } from "app/pages/material";

console.log(`Build Version:`, packageJson.version);

const { REACT_APP_CLIENT_ID, REACT_APP_AUTH_DOMAIN } = process.env;

const AppWrapper = () => {
  return (
    <div className="min-h-full bg-gradient-to-br from-[#DAF2F9] via-[#F0DAF9] to-[#D5F8F1]">
      <Outlet />
    </div>
  );
};

export const App = () => {
  return (
    <Auth0Provider
      domain={REACT_APP_AUTH_DOMAIN}
      clientId={REACT_APP_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Routes>
        <Route path="/" element={<AppWrapper />}>
          <Route index element={<Main />} />
          <Route path="/" element={<Main />} />
          <Route path="/material/:materialId" element={<Material />} />

          <Route path="*" element={() => <p>Page not found</p>} />
        </Route>
      </Routes>
    </Auth0Provider>
  );
};
